import { ArrowRightIcon, SparklesIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';

import FrameGrid from '../FrameGrid';
import Button from '../base/Button';
import Flex from '../base/Flex';
import Image from '../base/Image';
import Text from '../base/Text';

export function RodeoHero() {
  return (
    <Container>
      <FrameGrid.Root
        maxColumns={{ '@initial': 1, '@bp2': 2 }}
        css={{
          gridColumnGap: '$10',
          backgroundImage:
            'url(/images/homepage/hero/rodeo/hero-bg-mobile.webp)',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          borderRadius: '$2',
          minHeight: '80vh',
          overflow: 'hidden',
          '@bp2': {
            backgroundImage:
              'url(/images/homepage/hero/rodeo/hero-bg-desktop.webp)',
            padding: '80px',
            minHeight: '500px',
            backgroundSize: '150%',
            backgroundPosition: 'top left',
            overflow: 'visible',
          },
          '@bp3': {
            backgroundSize: '125%',
          },
          '@bp5': {
            padding: '100px',
            minHeight: '800px',
            backgroundPosition: 'center',
          },
          '@media (min-width: 1800px)': {
            backgroundSize: 'cover',
          },
        }}
      >
        <FrameGrid.Item
          css={{
            alignItems: 'center',
            width: '100%',
            order: 2,
            marginBottom: '-$10',
            '@bp2': {
              marginBottom: 0,
              order: 1,
              display: 'flex',
              alignItems: 'flex-end',
              marginY: '-100px',
            },
          }}
        >
          <IphoneContainer>
            <IphoneImageContainer>
              <IphoneImage
                src="/images/homepage/hero/rodeo/iphone.webp"
                alt="Rodeo"
              />
              <IphoneVideoContainer>
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  src="/videos/rodeo-x-fnd-hero.mp4"
                />
              </IphoneVideoContainer>
            </IphoneImageContainer>
          </IphoneContainer>
        </FrameGrid.Item>
        <FrameGrid.Item
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            gap: '$4',
            paddingTop: '$8',
            paddingX: '$4',
            order: 1,
            '@bp2': {
              paddingTop: 0,
              paddingX: 0,
              order: 2,
              alignItems: 'flex-start ',
              textAlign: 'left',
            },
          }}
        >
          <Flex
            css={{
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Tagline>
              <svg
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_ddddd_22_179)">
                  <path
                    d="M39.7078 29.2923L30.7064 20.2928C30.4204 20.0067 29.9904 19.9217 29.6164 20.0757C29.2424 20.2308 28.9994 20.5948 28.9994 20.9998V27.5852L24.3432 22.9289C23.9522 22.5379 23.3191 22.5379 22.9291 22.9289C22.5381 23.32 22.5381 23.953 22.9291 24.343L27.5853 28.9993H21C20.448 28.9993 20 29.4473 20 29.9994C20 30.5514 20.448 30.9994 21 30.9994H27.5853L22.9291 35.6557C22.5381 36.0468 22.5381 36.6798 22.9291 37.0698C23.1241 37.2648 23.3801 37.3628 23.6361 37.3628C23.8922 37.3628 24.1482 37.2648 24.3432 37.0698L28.9994 32.4135V38.9999C28.9994 39.552 29.4474 40 29.9994 40C30.5514 40 30.9994 39.552 30.9994 38.9999V32.4135L35.6566 37.0708C35.8516 37.2658 36.1077 37.3638 36.3637 37.3638C36.6197 37.3638 36.8757 37.2658 37.0707 37.0708C37.4617 36.6798 37.4617 36.0468 37.0707 35.6567L32.4135 30.9994H38.9998C39.4038 30.9994 39.7688 30.7554 39.9238 30.3824C40.0788 30.0084 39.9928 29.5783 39.7068 29.2923H39.7078ZM30.9994 28.9993V23.414L36.5857 28.9993H30.9994Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_ddddd_22_179"
                    x="-8"
                    y="-4"
                    width="76"
                    height="68"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.987834 0 0 0 0 0.391699 0 0 0 0 1 0 0 0 1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_22_179"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.987834 0 0 0 0 0.391699 0 0 0 0 1 0 0 0 1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="effect1_dropShadow_22_179"
                      result="effect2_dropShadow_22_179"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.987834 0 0 0 0 0.391699 0 0 0 0 1 0 0 0 1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="effect2_dropShadow_22_179"
                      result="effect3_dropShadow_22_179"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.987834 0 0 0 0 0.391699 0 0 0 0 1 0 0 0 1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="effect3_dropShadow_22_179"
                      result="effect4_dropShadow_22_179"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.987834 0 0 0 0 0.391699 0 0 0 0 1 0 0 0 1 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="effect4_dropShadow_22_179"
                      result="effect5_dropShadow_22_179"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect5_dropShadow_22_179"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>

              <Text
                size={{ '@initial': 2, '@bp3': 4 }}
                weight="regular"
                css={{ position: 'relative', zIndex: 1 }}
              >
                A new social app by Foundation
                <TextBlur aria-hidden="true" color="pink">
                  A new social app by Foundation
                </TextBlur>
              </Text>
            </Tagline>
            <h1>
              <Text
                weight="semibold"
                lineHeight={0}
                css={{
                  position: 'relative',
                  zIndex: 0,
                  letterSpacing: '-2px',
                  fontSize: '60px',
                  '@bp1': {
                    fontSize: '80px',
                    letterSpacing: '-3.2px',
                  },
                  '@bp5': {
                    letterSpacing: '-4.4px',
                    fontSize: '110px',
                  },
                }}
              >
                Introducing
                <div>Rodeo</div>
                <TextBlur aria-hidden="true" color="lilac">
                  Introducing Rodeo
                </TextBlur>
              </Text>
            </h1>
          </Flex>
          <Body>
            <Text size={3} weight="regular">
              On Rodeo, the process is celebrated—every brushstroke, every note,
              every line of code. It’s all part of the journey.
            </Text>
          </Body>
          <Flex
            css={{
              gap: '$4',
              flexDirection: 'column',
              alignItems: 'center',
              '@bp2': {
                alignItems: 'flex-start',
              },
              '@bp5': {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              },
            }}
          >
            <Button
              as="a"
              variant="primary"
              href={'https://rodeo.club'}
              target="_blank"
              size={2}
            >
              <Flex
                css={{
                  gap: '$4',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                Join Rodeo <ArrowRightIcon />
              </Flex>
            </Button>

            <Flex
              css={{
                gap: '$1',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '$2',
                '@bp5': {
                  marginTop: 0,
                },
              }}
            >
              <SparklesIcon />
              <Text size={2} weight="regular">
                Early access for Foundation users
              </Text>
            </Flex>
          </Flex>
        </FrameGrid.Item>
      </FrameGrid.Root>
    </Container>
  );
}

const Container = styled('div', {
  width: '100%',
  height: '100%',
  maxWidth: '$container',
  padding: '0 $4',
  margin: '0rem auto 4rem',
  '@bp2': {
    margin: '4rem auto 4rem',
    paddingX: '$8',
  },
});

const IphoneVideoContainer = styled('div', {
  width: '61.3%',
  height: 'auto',
  aspectRatio: '1/1.27',
  position: 'absolute',
  right: 0,
  marginLeft: 'auto',
  display: 'flex',
  zIndex: 1,
  top: 0,
  opacity: 1,
  transform: 'translate(-30%, 41%) skew(9.2deg, -7.4deg)',
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

const IphoneImageContainer = styled('div', {
  // position: 'absolute',
  // height: 'calc(100% + 200px)',
  // width: '100%',
  // right: 0,
  // top: '-100px',
  // objectFit: 'contain',
  // maxWidth: '10vw',
  // marginLeft: 'auto',
  // marginTop: '-50px',
  // marginBottom: '-50px',
  height: '100%',
  width: '100%',
  objectFit: 'contain',
});

const IphoneContainer = styled('div', {
  position: 'relative',
  height: '100%',
  marginX: 'auto',
  aspectRatio: '1/1.66',
  '@bp2': {
    marginLeft: 'auto',
  },
});

const IphoneImage = styled(Image, {
  position: 'absolute',
  height: 'calc(100% + 200px)',
  right: 0,
  top: '-100px',
  objectFit: 'contain',
});

const Body = styled('div', {
  maxWidth: '550px',
});

const TextBlur = styled('div', {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  filter: 'blur(18px)',
  zIndex: -1,
  variants: {
    color: {
      lilac: {
        color: '#CCABFF',
        WebkitTextStroke: '0.5ch #CCABFF',
        filter: 'blur(18px)',
      },
      pink: {
        color: '#FC64FF',
        WebkitTextStroke: '1ch #FC64FF',
        filter: 'blur(12px)',
      },
    },
  },
  defaultVariants: {
    color: 'lilac',
  },
});

const Tagline = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',

  '@bp2': {
    justifyContent: 'flex-start',
  },

  svg: {
    width: '60px',
    height: '60px',
    marginRight: '-$2',

    '@bp3': {
      width: '100px',
      height: '100px',
      marginRight: '-$5',
      marginLeft: '-$6',
    },
  },
});
