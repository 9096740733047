import { styled } from '@f8n-frontend/stitches';
import { useQuery } from '@tanstack/react-query';
import { GetStaticPropsContext, InferGetServerSidePropsType } from 'next';

import DarkMode from 'components/DarkMode';
import FeaturedSection from 'components/FeaturedSection';
import FrameGrid from 'components/FrameGrid';
import { MomentCalendarButton } from 'components/MomentCalendarButton';
import MomentGridItem from 'components/MomentGridItem';
import PageHead from 'components/PageHead';
import PreviewMode from 'components/PreviewMode';
import { SpotlightContentType } from 'components/SpotlightContent';
import Body from 'components/base/Body';
import Box from 'components/base/Box';
import Footer from 'components/footers/Footer';
import Header from 'components/headers/Header';
import { RodeoHero } from 'components/homepage/RodeoHero';
import WorksGrid from 'components/homepage/WorksGrid';
import WorldsGrid from 'components/homepage/WorldsGrid';
import { hasToken } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';

import { useMomentBookmarks } from 'gql/api/mutations/moment-bookmarks.generated';
import { useFeaturedMoments } from 'gql/api/queries/featured-moments.generated';
import { useFeaturedWorks } from 'gql/api/queries/featured-works.generated';
import { useHomepageTopSlotCollection } from 'gql/api/queries/homepage-top-slot-collection.generated';
import { useHomepageTopSlotToken } from 'gql/api/queries/homepage-top-slot-token.generated';
import { useMomentById } from 'gql/api/queries/moment-by-id.generated';
import { useMomentCreators } from 'gql/api/queries/moment-creators.generated';
import { getWorldBySlug } from 'hooks/queries/use-world-by-slug';
import { getHomepageContentfulValues } from 'queries/server/content';
import { getActiveBookmarkId } from 'utils/bookmark';
import { TopSlotConfig } from 'utils/contentful';
import { getCollectionFilter, getTokenFilter } from 'utils/inputs';
import { getPath } from 'utils/router';

// TODO(LukeBailey): Uncomment when removing RodeoHero
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HeroBody = styled(Body, {
  paddingY: '$3',
  textAlign: 'left',
  '@bp1': {
    paddingTop: '$7',
    paddingBottom: '$8',
  },
});

export default function IndexPage(
  props: InferGetServerSidePropsType<typeof getStaticProps>
) {
  const {
    preview,
    featuredWorldSlugs,
    moments,
    works,
    // TODO(LukeBailey): Uncomment when removing RodeoHero
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tagline,
  } = props;

  const spotlightQuery = useQuery({
    queryFn: () => getHomepageTopSlot(props.topSlotConfig),
    queryKey: ['featured', 'content', props.topSlotConfig],
  });

  // TODO(LukeBailey): Uncomment when removing RodeoHero
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const spotlight = spotlightQuery.data || props.topSlot;

  const auth = useAuth();

  const momentBookmarksQuery = useMomentBookmarks(
    {
      publicKey: hasToken(auth) ? auth.publicKey : '',
      page: 0,
      perPage: 100,
    },
    { enabled: hasToken(auth) }
  );

  const getBookmarkId = (momentId: number) => {
    if (!momentBookmarksQuery.data) {
      return null;
    }

    return getActiveBookmarkId({
      momentId,
      bookmarks: momentBookmarksQuery.data.momentBookmarks.items,
    });
  };

  // TODO(LukeBailey): Uncomment when removing RodeoHero
  // const getHero = () => {
  //   if (spotlight.type !== 'UNKNOWN') {
  //     return (
  //       <>
  //         {Boolean(tagline) && (
  //           <>
  //             <HeroBody css={{ paddingBottom: '$5' }}>
  //               <H1Heading
  //                 weight="medium"
  //                 size={{ '@initial': 4, '@bp0': 5 }}
  //                 css={{ textWrap: 'balance' }}
  //               >
  //                 {tagline}
  //               </H1Heading>
  //             </HeroBody>
  //             <Divider />
  //           </>
  //         )}
  //         <HeroBody css={{ paddingBottom: '$8' }}>
  //           <SpotlightContent {...spotlight} />
  //         </HeroBody>
  //       </>
  //     );
  //   }

  //   return null;
  // };

  const momentMaxColumns =
    moments.items && moments.items.length % 3 === 0 ? 3 : 2;

  return (
    <>
      <PageHead />
      <Header absolute={false} mode="LIGHT" type="MINIMAL" />
      {/* TODO(LukeBailey): Uncomment when removing RodeoHero */}
      {/* {getHero()} */}

      <RodeoHero />

      {works.items.length > 0 && (
        <FeaturedSection title={works.title}>
          <WorksGrid works={works} />
        </FeaturedSection>
      )}
      {moments.items.length > 0 && (
        <FeaturedSection title={moments.title}>
          <FrameGrid.Root
            css={{ maxWidth: '$container' }}
            maxColumns={momentMaxColumns}
          >
            {moments.items.map(({ moment, momentStats }) => (
              <MomentGridItem
                key={moment.id}
                moment={moment}
                momentStats={momentStats}
                action={
                  <MomentCalendarButton
                    moment={moment}
                    bookmarkId={getBookmarkId(moment.id)}
                    variant="subtle"
                  />
                }
              />
            ))}
          </FrameGrid.Root>
        </FeaturedSection>
      )}
      <FeaturedSection
        link={{ href: getPath.browse.galleries, text: 'View all' }}
        title="Featured galleries"
      >
        <WorldsGrid slugs={featuredWorldSlugs} />
      </FeaturedSection>
      {preview && <PreviewMode />}
      <Box css={{ backgroundColor: '$black100' }}>
        <DarkMode>
          <Footer footerMode="DARK" type="MAXIMAL" />
        </DarkMode>
      </Box>
    </>
  );
}

export async function getStaticProps({
  preview = false,
}: GetStaticPropsContext) {
  const { topSlotConfig, featuredWorldSlugs } =
    await getHomepageContentfulValues({ preview });
  const fetchMoments = useFeaturedMoments.fetcher();
  const fetchFeaturedWorks = useFeaturedWorks.fetcher();

  const [topSlot, momentsData, worksData] = await Promise.all([
    getHomepageTopSlot(topSlotConfig),
    fetchMoments(),
    fetchFeaturedWorks(),
  ]);

  return {
    props: {
      preview,
      topSlotConfig,
      topSlot,
      featuredWorldSlugs,
      moments: momentsData.featuredMoments,
      works: worksData.featuredWorks,
      tagline: false,
    },
    // refresh every 5 mins
    revalidate: 60 * 5,
  };
}

async function getHomepageTopSlot(
  config: TopSlotConfig
): Promise<SpotlightContentType> {
  if (config.type === 'mintable-collection') {
    const collectionFilter = getCollectionFilter(config.filter);
    const getCollection =
      useHomepageTopSlotCollection.fetcher(collectionFilter);

    const { collection, moment } = await getCollection();

    if (!collection) {
      throw Error('No collection found');
    }

    if (collection.__typename === 'ERC1155Collection') {
      throw Error('Unsupported collection');
    }

    if (collection.__typename === 'StandardCollection') {
      throw Error('Unsupported collection');
    }

    if (!collection.chainId) {
      throw Error('No collection chainId found');
    }

    const { world } = collection;

    if (collection.__typename === 'DropCollection') {
      return {
        type: 'DROP',
        content: {
          collection,
          moment,
          world,
        },
      };
    } else if (collection.__typename === 'EditionCollection') {
      return {
        type: 'EDITION',
        content: {
          collection,
          moment,
          world,
        },
      };
    } else {
      throw Error('No collection found');
    }
  } else if (config.type === 'token') {
    const nftFilter = getTokenFilter(config.filter);
    const getToken = useHomepageTopSlotToken.fetcher(nftFilter);

    const { nft, moment } = await getToken();

    if (!nft) {
      throw Error('No NFT found');
    }

    const { world } = nft;

    return {
      type: 'NFT',
      content: {
        nft,
        moment,
        world,
      },
    };
  } else if (config.type === 'moment') {
    const fetchMomentById = useMomentById.fetcher({
      id: config.momentId,
    });
    const fetchCreators = useMomentCreators.fetcher({
      momentId: config.momentId,
    });

    const [world, momentQuery, creators] = await Promise.all([
      getWorldBySlug({ slug: config.worldSlug }),
      fetchMomentById(),
      fetchCreators(),
    ]);

    const moment = momentQuery.moment;

    if (!moment) {
      throw Error('No moment found');
    }

    return {
      type: 'MOMENT',
      content: {
        moment,
        world,
        creators,
      },
    };
  } else {
    throw Error('No featured content found');
  }
}
